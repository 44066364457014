.watchProvidersContainer {
  overflow: scroll;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.watchProviderButtonContainer {
}

.watchProvidersContainer img.logo {
  margin: 5px;
  background-color: #2D2D2D;
  border-radius: 9px;
  padding: 5px;
  height: 45px;
}

.watchProvidersContainer img.logo:hover {
  background-color: gray;
  transform: scale(95%);
}
