@import '~antd/dist/antd.css';

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

body {
  background: #f0f2f5;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ant-layout-header .logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}
.ant-layout-sider .logo {
  height: 32px;
  margin: 16px;
  text-align: center;
}
.ant-layout-sider .logo img {
  height: 100%;
}
.site-layout .site-layout-background {
  background: #fff;
}

.header-right {
  text-align: right;
  padding-right: 15px;
}

.rdw-editor-wrapper {
  border: 1px solid #d9d9d9;
}

.description-editor-wrapper {
  min-height: 200px
}

.ant-form-item-control {
  text-align: left;
}

.images-scroll-item {
  float: left;
  padding: 10px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  margin-bottom: 10px;
}