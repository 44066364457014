.auth-container .logo {
    background-color: #001528;
    height: 100%;
    text-align: center;
    padding: 30px;
}

.auth-container .logo img {
    height: 70px;
    margin-top: 7%;
}